<template>
  <div class="workplace-file">
    <div class="flex-row flex-start wrap-header">
      <el-input
        style="width: 300px; margin-right: 10px"
        v-model="keyWord"
        placeholder="请输入附件关键字"
      ></el-input>
      <el-button @click="queryData" type="primary">查询</el-button>
    </div>

    <div class="flex-column-between collection-container">
      <!--列表-->
      <el-table
        :data="tableData"
        style="width: 100%"
        stripe
        border
        max-height="500"
        size="medium"
      >
        <el-table-column label="序号" type="index" width="60" align="center">
          <template slot-scope="scope">
            <p>{{ scope.$index + 1 }}</p>
          </template>
        </el-table-column>

        <el-table-column label="附件名称" min-width="100">
          <template slot-scope="scope">
            <span class="max-one-line">{{ scope.row.filename }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="上传日期"
          width="200"
          align="center"
        ></el-table-column>

        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="showFile(scope.$index)"
              type="primary"
              size="medium"
              >查看
            </el-button>

            <el-button
              @click.native.prevent="deleteFile(scope.$index)"
              type="danger"
              size="medium"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        style="margin-top: 20px"
        layout="total, prev, pager, next"
        background
        hide-on-single-page
        :total="total"
        :page-size="pageSize"
        @current-change="changePageNum"
      ></el-pagination>
    </div>
    <!-- 查看材料 -->
<!--    <el-dialog-->
<!--      title=""-->
<!--      :visible.sync="showMaterial"-->
<!--      modal-append-to-body-->
<!--      width="90%"-->
<!--    >-->
<!--&lt;!&ndash;      <img :src="imgUrl" alt="" width="1200px" height="1000px" style="margin-left: calc(50% - 600px);">&ndash;&gt;-->
<!--      &lt;!&ndash; <embed type="image/jpg" :src="imgUrl" /> &ndash;&gt;-->
<!--&lt;!&ndash;       <iframe type="image/jpg/png" :src="imgUrl" width="800px" height="500px"/> &ndash;&gt;-->
<!--      <embed :src="imgUrl" type="application/pdf" width="100%" height="100%" />-->

<!--&lt;!&ndash;      <iframe :src="imgUrl" width="800px" height="500px"/>&ndash;&gt;-->
<!--    </el-dialog>-->

    <el-dialog
        title="提示"
        :visible.sync="showMaterial"
        width="90%"
        append-to-body
        top="1vh"
    >
      <embed :src="imgUrl" width="100%" height="750px" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      keyWord: "", //搜索关键字
      tableData: [],
      total: 0,
      currentPageNum: 1,
      pageSize: 6,
      activeName: "0",
      src: require("@/assets/404_images/no_data.png"),
      showMaterial: false,
      UrlPre:process.env.VUE_APP_DATA_URL,
      imgUrl:"",
      pdfUrl:
          process.env.VUE_APP_BASE_API +
          "/common/pdfView?file=" +
          process.env.VUE_APP_BASE_API +
          "/common/fileView/",
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
  },
  created() {
    this.getDataList();
  },
  methods: {
    queryData() {
      let keyword = this.keyWord;
      let pageSize = this.pageSize;
      let param = {
        pageNum: 1,
        pageSize: pageSize,
        filename: keyword,
      };
      if (this.userInfo) {
        let loginId = this.userInfo.userId;
        this.$httpApi
          .get("/dev-api/fileRecord/list/" + loginId, param)
          .then((res) => {
            if (res.code == 200) {
              this.total = res.total;
              this.tableData = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    //请求数据
    getDataList() {
      let pageNum = this.currentPageNum;
      let keyword = this.keyWord;
      let pageSize = this.pageSize;
      let param = {
        pageNum: pageNum,
        pageSize: pageSize,
        fileName: keyword,
      };

      if (this.userInfo) {
        let loginId = this.userInfo.userId;
        this.$httpApi
          .get("/dev-api/fileRecord/list/" + loginId, param)
          .then((res) => {
            if (res.code == 200) {
              this.total = res.total;
              this.tableData = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    changePageNum(current) {
      //current是当前页数
      this.currentPageNum = current;
      this.getDataList();
    },
    //删除文件
    deleteFile(index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonType: "primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let item = this.tableData[index];
          let ids = [item.id];
          this.$httpApi
            .post("/dev-api/fileRecord/delete", ids)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });

                //刷新数据
                this.getDataList();
              }
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    //查看文件
    showFile(index) {
      debugger
      let item = this.tableData[index];
      if (item.fileid) {
        this.imgUrl = this.pdfUrl + item.fileid + "/view";
        debugger
        // window.open(href, "_blank");
        this.showMaterial = true;

      } else {
        this.$message({
          type: "warning",
          message: "文件不存在",
        });
      }
      // if (item.viewUrl) {
      //   // window.open(item.viewUrl);
      //   this.showMaterial = true;
      //   // 修正图片地址
      //   let str = item.viewUrl.substring(item.viewUrl.indexOf("/balocal-api")+12)
      //   this.imgUrl = this.UrlPre + str + "/view";
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "文件地址不对",
      //   });
      // }
    },

    indexMethod(index) {
      return index + 1;
    },
  },
};
</script>
<style lang="scss">
.collection-container {
  height: 100%;
  align-items: flex-start;
  .col-nav {
    // width: 100%;

    .el-tabs {
      .el-tabs__header {
        // background: #333;
        .el-tabs__nav {
          height: 50px;
        }

        .el-tabs__item {
          height: 26px;
          // color:#2d81ff;
          font-family: Microsoft YaHei;
          font-size: 16px;
          font-weight: bold;
          line-height: 45px;
        }

        .el-tabs__active-bar {
          width: 81px;
          height: 3px;
          background-color: #2d81ff;
        }
      }
    }

    .img {
      width: 80%;
      // background: red;
    }
  }
}
</style>
<style lang="scss" scoped>
.workplace-file {
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight} - 60px);
}
.wrap-header {
  padding: 15px 20px;
  margin-bottom: 10px;
  background-color: #ffffff;
}
::v-deep {
  .el-button:not([disabled]).el-button--primary,
  .el-button:not([disabled]).el-button--primary:hover,
  .el-button:not([disabled]).el-button--primary:active {
    border-color: #4795f1;
    background-color: #4795f1;
  }
}
</style>
